import React from 'react'
import Layout from '../layouts/Layout'
import BasicHero from '../components/Hero/BasicHero'
import { Container } from 'theme-ui'
import { Link } from 'gatsby'

function TermsOfUse() {
  return (
    <Layout theme={'dark'}>
      <BasicHero heading="Terms of Use" />
      <section>
        <Container
          sx={{
            flexDirection: ['column', null, null, 'row'],
          }}
        >
          <div
            sx={{
              width: ['100%', null, null, '30%'],
            }}
          >
            <h2
              sx={{
                pb: 4,
                fontSize: 38,
                textAlign: 'left',
              }}
            >
              Terms of Use
            </h2>
            <b
              sx={{
                mt: 4,
                color: 'brand.matisse',
              }}
            >
              Effective Date: June 28, 2024
            </b>
          </div>
          <div
            sx={{
              width: ['100%', null, null, '70%'],
              textAlign: 'left',
              a: {
                color: 'brand.matisse',
                fontWeight: 500,
                textDecoration: 'underline',
              },
              h2: {
                fontSize: 38,
                pb: 3,
                pt: [3, null, null, 0],
                textAlign: 'left',
              },
              h3: {
                fontSize: 20,
                py: 4,
              },
              ul: {
                li: {
                  fontWeight: 300,
                  lineHeight: 1.5,
                },
              },
            }}
          >
            <p>
              These Terms and Conditions of Use ("Terms of Use") apply to the
              website owned, operated, licensed, and controlled by Curo Group
              Holdings LLC d/b/a Attain Finance (“Attain Finance"), including
              its direct and indirect subsidiaries located at{' '}
              <a href="https://www.attainfinance.com">www.attainfinance.com</a>,
              and all associated webpages (collectively, the "Site"). This Site
              is the property of Attain Finance. As used herein, "Customer(s)"
              means anyone who accesses and/or uses the Site.
            </p>

            <p>
              PLEASE READ THE FOLLOWING TERMS CAREFULLY. BY DOWNLOADING OR USING
              ANY OF THE INFORMATION, MATERIALS, OR FUNCTIONS AVAILABLE ON THE
              SITE, YOU AGREE TO BE BOUND BY THESE TERMS OF USE. YOU ACKNOWLEDGE
              THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO THESE TERMS OF USE,
              INCLUDING OUR PRIVACY POLICY. IF YOU ARE NOT ELIGIBLE TO USE OUR
              SERVICES (PLEASE SEE OUR ELIGIBILITY CRITERIA BELOW), OR DO NOT
              AGREE TO THESE TERMS, THEN PLEASE DO NOT USE OUR SERVICES OR THE
              SITE.
            </p>

            <h2>GENERAL TERMS</h2>
            <h3>Privacy Policy</h3>
            <p>
              Please review our <Link to="/privacy-policy">Privacy Policy</Link>{' '}
              to understand our practices, including the collection and use of
              personally identifiable information (“PII”). Our Privacy Policy is
              incorporated by reference in these Terms of Use. By using the
              Site, you are also consenting to the Privacy Policy.
            </p>

            <h3>Changes to Terms of Use</h3>
            <p>
              We may make changes to these Terms of Use from time to time.
              Changes will be posted on the Site and the date the Terms of Use
              were last revised will be indicated at the top of the page. You
              understand and agree that your continued use of the Site after we
              have made any such changes constitutes your acceptance of the new
              Terms of Use.
            </p>

            <h3>Eligibility; General</h3>
            <p>
              This Site is intended solely for natural persons, 18 years of age
              or older. Use by those that do not fit these requirements is in
              violation of these Terms of Use. By using the Site, you represent
              and warrant that, if you are a natural person and 18 years of age
              or older, you agree to and will abide by all of the terms and
              conditions of these Terms of Use. If you violate any of these
              Terms of Use, or otherwise violate an agreement between you and
              us, Attain Finance may prohibit you from using or accessing the
              Site at any time in its sole discretion, with or without notice,
              including, without limitation, if it believes that you are under
              18 years of age.
            </p>
            <h3>Restrictions on Data Collection and Termination</h3>
            <p>Without our prior consent, you may not:</p>
            <ul>
              <li>
                Use any automated means to access this Site or collect any
                information from the Site (including, without limitation,
                robots, spiders, scripts or other automatic devices or
                programs);
              </li>
              <li>
                Frame the Site, utilize framing techniques to enclose any
                content or other proprietary information, place pop-up windows
                over this Site's pages, or otherwise affect the display of this
                Site's pages;
              </li>
              <li>
                Engage in the practices of "screen scraping," "database
                scraping,” or any other activity with the purpose of obtaining
                content or other information;
              </li>

              <li>
                Upload, post, email, transmit or otherwise make available any
                unsolicited or unauthorized advertising, promotional materials,
                "junk mail," "spam," "chain letters," "pyramid schemes," or any
                other form of commercial or non-commercial solicitation or bulk
                communications of any kind to any employee or third party;
              </li>
              <li>
                Use data provided by Attain Finance, provided in any manner
                whatsoever, for any competing uses or purposes. You further
                agree that you have never used data, provided in any manner
                whatsoever, from Attain Finance in the past to compete with the
                products or services of Attain Finance; or
              </li>
              <li>
                Use this Site in any manner that violates applicable law or that
                could damage, disable, overburden, or impair this Site or
                interfere with any other party's use and enjoyment of this Site.
              </li>
            </ul>

            <h3>Customer Representations</h3>
            <p>
              You agree that you may not use the Site in any unlawful manner or
              in any other manner that could damage, disable, overburden or
              impair the Site. In addition, you agree not to use the Site to:
            </p>
            <ul>
              <li>
                Upload, post, transmit, share, store or otherwise make available
                any content that we deem to be harmful, threatening, unlawful,
                defamatory, infringing, abusive, inflammatory, harassing,
                vulgar, obscene, fraudulent, invasive of privacy or publicity
                rights, hateful, or racially, ethnically or otherwise
                objectionable;
              </li>
              <li>
                Upload, post, transmit, share or otherwise make available any
                material that contains software viruses or any other malicious
                or tracking computer code, files or programs designed to track,
                interrupt, destroy or limit the functionality of any computer
                software or hardware or telecommunications equipment;
              </li>
              <li>
                Do any of the following: (i) access data that is not intended
                for you; (ii) invade the privacy of, obtain the identity of, or
                obtain any personal information about any Customer of this Site;
                (iii) probe, scan or test the vulnerability of this Site or the
                Attain Finance network or breach security or authentication
                measures without proper authorization; (iv) attempt to interfere
                with service to any Customer, host or network or otherwise
                attempt to disrupt our business, including, without limitation,
                via means of submitting a virus to this Site, overloading,
                "flooding," "spamming," "mail bombing" or "crashing;" or (v)
                send unsolicited email, including promotions and/or advertising
                of products and services. Violations of system or network
                security may result in civil or criminal liability.
              </li>
              <li>
                Violate, or encourage others to violate, the rights of any
                party, including by infringing or misappropriating third party
                intellectual property rights;
              </li>
              <li>
                Violate the Terms of Use or any other Attain Finance policy;
              </li>
              <li>
                Upload, post, transmit, share, store or otherwise make available
                content that would constitute, encourage or provide instructions
                for a criminal offense, violate the rights of any party, or that
                would otherwise create liability or violate any local, state,
                national or international law;
              </li>
              <li>
                Upload, post, transmit, share, store or otherwise make available
                content that, in the sole judgment of Attain Finance, is
                objectionable or which restricts or inhibits any other person
                from using or enjoying the Site, or which may expose Attain
                Finance or its Customers to any harm or liability of any type;
                or
              </li>
              <li>
                Attempt to do any prohibited conduct, or inappropriate use, or
                assist or permit any persons in engaging or attempting to engage
                in any prohibited conduct, or inappropriate use, as determined
                by us in our sole discretion.
              </li>
            </ul>
            <h3>POTENTIAL DISRUPTION OF SERVICE</h3>
            <p>
              Access to our Site may from time to time be unavailable, delayed,
              limited or slowed due to causes beyond our control. We make no
              guarantees as to the availability of the Site.
            </p>
            <h3>SITE AND ACCOUNT SECURITY</h3>
            <p>
              You acknowledge and agree that Internet transmissions are never
              completely private or secure and that any message or information
              you send to the Site may be read or intercepted by others,
              notwithstanding our efforts to protect such transmissions. We use
              industry standard physical, technical and administrative security
              measures and safeguards to protect the confidentiality and
              security of your personal information. However, since the Internet
              is not a 100% secure environment, we cannot guarantee, ensure, or
              warrant the security of any information you transmit to us. There
              is no guarantee that information may not be accessed, disclosed,
              altered, or destroyed by breach of any of our physical, technical,
              or administrative safeguards. Please note that emails and other
              communications you send to us through our Site are not encrypted.
            </p>
            <h3>Mobile Device Usage</h3>
            <p>
              Use of our services may be made available through a browser on a
              mobile device or through an application running on a mobile
              device. You are responsible for all costs incurred by you with
              respect to using our service on a mobile device, including data
              usage fees and other telecommunications fees.
            </p>
            <h3 sx={{ py: 4 }}>INTELLECTUAL PROPERTY MATTERS</h3>
            <b>Trademarks</b>
            <p>
              Attain Finance graphics, logos, designs, page headers, button
              icons, scripts and service names are its trademarks (collectively,
              “Trademarks”). Attain Finance's Trademarks may not be used,
              including as part of trademarks and/or as part of domain names, in
              connection with any product or service in any manner that is
              likely to cause confusion in the marketplace and may not be
              copied, imitated, or used, in whole or in part, without the prior
              written permission of Attain Finance.
            </p>
            <b>Copyright Complaints</b>
            <p>
              If you believe that any material on the Site infringes upon any
              copyright which you own or control, you may send a written
              notification of such infringement to the following address: 101 N.
              Main Street, #600 Greenville, SC 29601, Attn: Legal Department, as
              set forth below:
            </p>
            <ul>
              <li>
                Name of Agent Designated to Receive Notification of Claimed
                Infringement;
              </li>
              <li>
                Full Address of Designated Agent to Which Notification should be
                Sent;
              </li>
              <li>Telephone Number of Designated Agent;</li>
              <li>Facsimile Number of Designated Agent; and</li>
              <li>Email Address of Designated Agent;</li>
            </ul>

            <p>
              To meet the notice requirements under the Digital Millennium
              Copyright Act, the notification must be a written communication
              that includes the following:
            </p>
            <ul
              sx={{
                listStyle: 'numeric',
                pb: 4,
              }}
            >
              <li>
                A physical or electronic signature of a person authorized to act
                on behalf of the owner of an exclusive right that is allegedly
                infringed;
              </li>
              <li>
                Identification of the copyrighted work claimed to have been
                infringed, or, if multiple copyrighted works at a single online
                site are covered by a single notification, a representative list
                of such works at that site;
              </li>
              <li>
                Identification of the material that is claimed to be infringing
                or to be the subject of infringing activity and that is to be
                removed or access to which is to be disabled, and information
                reasonably sufficient to permit us to locate the material;
              </li>
              <li>
                Information reasonably sufficient to permit us to contact the
                complaining party, such as an address, telephone number and, if
                available, an electronic email address at which the complaining
                party may be contacted;
              </li>
              <li>
                A statement that the complaining party has a good-faith belief
                that use of the material in the manner complained of is not
                authorized by the copyright owner, its agent or the law; and
              </li>
              <li>
                A statement that the information in the notification is
                accurate, and under penalty of perjury, that the complaining
                party is authorized to act on behalf of the owner of an
                exclusive right that is allegedly infringed.
              </li>
            </ul>

            <b>Submissions</b>
            <p>
              You acknowledge and agree that any questions, comments,
              suggestions, ideas, feedback or other information about the Site
              (collectively, "Submissions"), provided by you to Attain Finance
              are not confidential and shall become the sole property of Attain
              Finance. Attain Finance shall own exclusive rights, including all
              intellectual property rights, and shall be entitled to the
              unrestricted use and dissemination of these Submissions for any
              purpose, commercial or otherwise, without acknowledgment or
              compensation to you.
            </p>
            <h3>MISCELLANEOUS TERMS</h3>
            <b>Links to Other Websites and Content</b>
            <p>
              The Site may contain (or you may be sent through the Site) links
              to other websites ("Third Party Sites"), as well as articles,
              photographs, text, graphics, pictures, designs, music, sound,
              video, information, software and other content belonging to or
              originating from third parties (the "Third Party Content"). Such
              Third Party Sites and Third Party Content are not investigated,
              monitored or checked for accuracy, appropriateness, or
              completeness by us, and we are not responsible for any Third Party
              Sites accessed through the Site or any Third Party Content posted
              on the Site, including without limitation the content, accuracy,
              offensiveness, opinions, reliability or policies of or contained
              in the Third Party Sites or the Third Party Content. Inclusion of
              or linking to any Third Party Site or any Third Party Content does
              not imply approval or endorsement thereof by us. If you decide to
              leave the Site and access the Third Party Sites, you do so at your
              own risk and you should be aware that our Terms of Use no longer
              govern. You should review the applicable terms and policies,
              including privacy and data gathering practices, of any Third Party
              Site to which you navigate from the Site.
            </p>

            <b>Disclaimers</b>
            <p>
              ATTAIN FINANCE DOES NOT PROMISE THAT THE SITE OR ANY CONTENT,
              DOCUMENT OR FEATURE OF THE SITE WILL BE ERROR-FREE OR
              UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED OR THAT YOUR
              USE OF THE SITE WILL PROVIDE SPECIFIC RESULTS. THE MATERIAL IN
              THIS SITE COULD INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL
              ERRORS. THE SITE AND ITS CONTENT ARE DELIVERED ON AN "AS-IS" AND
              "AS-AVAILABLE" BASIS. ALL INFORMATION PROVIDED ON THE SITE IS
              SUBJECT TO CHANGE WITHOUT NOTICE. CURO CANNOT ENSURE THAT ANY
              FILES, DOCUMENTS OR OTHER DATA YOU DOWNLOAD FROM THE SITE WILL BE
              FREE OF VIRUSES OR CONTAMINATION OR DESTRUCTIVE FEATURES. CURO
              DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY
              WARRANTIES OF ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND
              FITNESS FOR A PARTICULAR PURPOSE. CURO DISCLAIMS ANY AND ALL
              LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT OF ANY THIRD PARTIES
              IN CONNECTION WITH OR RELATED TO YOUR USE OF THE SITE. YOU ASSUME
              TOTAL RESPONSIBILITY FOR YOUR USE OF THE SITE AND ANY LINKED
              SITES. YOUR SOLE REMEDY AGAINST CURO FOR DISSATISFACTION WITH THE
              SITE OR ANY CONTENT IS TO STOP USING THE SITE OR ANY SUCH CONTENT.
              THIS LIMITATION OF RELIEF IS A PART OF THE BARGAIN BETWEEN THE
              PARTIES.
            </p>
            <b>Limitation on Liability</b>
            <p>
              EXCEPT WHERE PROHIBITED BY LAW, NEITHER ATTAIN FINANCE, NOR ITS
              DIRECTORS, MANAGERS, MEMBERS, OFFICERS, EMPLOYEES, AGENTS,
              CONTRACTORS, SUCCESSORS OR ASSIGNS OF EACH, SHALL BE LIABLE TO YOU
              FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL OR PUNITIVE
              DAMAGES, INCLUDING LOST PROFITS.
            </p>
            <p>
              IN NO EVENT WILL ATTAIN FINANCE OR ITS DIRECTORS, MANAGERS,
              MEMBERS, EMPLOYEES OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY
              FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
              PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA
              ARISING FROM YOUR USE OF THE SITE OR THE SERVICE OR ANY OF THE
              SITE CONTENT OR OTHER MATERIALS ON OR ACCESSED THROUGH THE SITE,
              EVEN IF CURO IS AWARE OR HAS BEEN ADVISED OF THE POSSIBILITY OF
              SUCH DAMAGES.
            </p>
            <b>Governing Law; Venue and Jurisdiction</b>
            <p>
              By visiting or using the Site, you agree that the laws of the
              State of Delaware will govern these Terms of Use, without regard
              to any principles of conflict of laws that would require or permit
              the application of the laws of any other jurisdiction. All
              disputes arising out of your use of this Site shall be brought
              exclusively in state or federal court in the State of Delaware and
              you hereby submit to the jurisdiction of such courts.
            </p>
            <b>Indemnity</b>
            <p>
              By using the Site, you agree to indemnify and hold Attain Finance,
              its subsidiaries and affiliates, and each of their directors,
              managers, officers, agents, contractors, partners and employees,
              harmless from and against any loss, liability, claim, demand,
              damages, costs and expenses, including reasonable attorney's fees,
              arising out of or in connection with your Customer content, any
              Third Party Content you post or share on or through the Site, your
              use of the Site, your conduct in connection with the Site or with
              other Customers of the Site, or any violation of these Terms of
              Use or of any law or the rights of any third party.
            </p>
            <b>Severability</b>
            <p>
              Any failure by Attain Finance to exercise any rights or enforce
              any of these Terms of Use shall not constitute a waiver of such
              rights or terms with regard to the use of the Site. If any
              provision of these Terms of Use or their application in a
              particular circumstance is held to be invalid or unenforceable to
              any extent, the remainder of these Terms of Use, or the
              application of such provision in other circumstances, shall not be
              affected thereby, and each provision hereof shall be valid and
              enforced to the fullest extent permitted by law. These Terms of
              Use constitute the entire agreement between you and Attain Finance
              with regard to your use of the Site, and any and all other written
              or oral agreements or understandings previously existing between
              you and Attain Finance with respect to such use are hereby
              superseded and cancelled.
            </p>

            <h3>Contact Information</h3>
            <p>
              If you have questions about these Terms of Use, please contact us:
            </p>

            <p>
              <b>Phone:</b> 1-877-252-4835
            </p>
            <p>
              <b>Email:</b> privacy@attainfinance.com
            </p>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default TermsOfUse
